<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center mx-auto">
        <v-img
          alt="Logo"
          contain
          position="center center"
          src="./assets/logo.svg"
          transition="scale-transition"
          width="200px"
          color="white"
        />
      </div>
    </v-app-bar>

    <v-main class="pt-80 pb-15">
      <router-view></router-view>
    </v-main>
    <v-bottom-navigation fixed shift color="primary">
      <v-btn value="favorites" to="/">
        <span>Status</span>

        <v-icon>mdi-chart-box</v-icon>
      </v-btn>
      <v-btn value="recent" to="stats">
        <span>Statistics</span>

        <v-icon>mdi-timetable</v-icon>
      </v-btn>
      <v-btn value="terms" to="terms">
        <span>Terms</span>
        <v-icon>mdi-file-document-edit</v-icon>
      </v-btn>
      <v-btn value="giveaway" to="giveaway">
        <span>Giveaway</span>
        <v-icon>mdi-gift</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
export default {
  created() {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
    }

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        this.$vuetify.theme.dark = e.matches;
      });
  },
};
</script>

<style lang="scss">
.pt-80 {
  padding-top: 80px !important;
}

//Fix for bottom navigation in production
#app .v-bottom-navigation .v-btn {
  height: inherit !important;
  background-color: transparent;
}
</style>