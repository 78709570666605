/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (registration) {
      // console.log(
      //   'App is being served from cache by a service worker.\n' +
      //   'For more details, visit https://goo.gl/AFskqB'
      // )

      subscribe(registration).catch(err => console.error(err));
    },
    // registered () {
    //   console.log('Service worker has been registered.')
    // },
    // cached () {
    //   console.log('Content has been cached for offline use.')
    // },
    // updatefound () {
    //   console.log('New content is downloading.')
    // },
    // updated () {
    //   console.log('New content is available; please refresh.')
    // },
    // offline () {
    //   console.log('No internet connection found. App is running in offline mode.')
    // },
    // error (error) {
    //   console.error('Error during service worker registration:', error)
    // }
  })
}

async function subscribe(registration) {
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array('BHXbiO9wXWzJFpZVr_PDY1XZx8WiPilxCPTDzBqro-ToSiriUF4JRKBPEv8EscFhMm4WKFwqjVx6VvBxiMtWr9U')
  });

  await fetch('https://dev.level3.mk:555/', {
    method: 'POST',
    body: JSON.stringify(subscription),
    headers: {
      'content-type': 'application/json'
    }
  })
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}