import { SET_TL, SET_LOADING } from "../mutation-types";
import moment from "moment";

const state = {
  timelogs: [],
  loading: false
};

const mutations = {
  [SET_TL](state, timelogs) {
    state.timelogs = timelogs.map(tl => {
      tl.headshots = Math.round(tl.headshots / tl.kills * 100 || 0) + '%';
      tl.subscription_end = moment(tl.subscription_end).format("DD.MM.YYYY");
      return tl;
    });
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  }
};

const actions = {
  async getTL({ commit }) {
    try {
      commit(SET_LOADING, true);
      let response = await fetch("https://dev.level3.mk:555/timelogs");
      let { data } = await response.json();
      commit(SET_TL, data);
      commit(SET_LOADING, false);

    } catch (error) {
        console.error(error);
    }
  }
};

const getters = {
  timelogs: (state) => state.timelogs,
  loading: (state) => state.loading,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
