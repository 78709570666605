import { SET_CONTESTANTS, SET_CONTESTANT } from '../mutation-types';

const state = {
  contestants: [],
  picked: {}
};

const mutations = {
  [SET_CONTESTANTS](state, contestants) {
    state.contestants = contestants;
  },

  [SET_CONTESTANT](state, contestant) {
    // const index = state.contestants.findIndex(player => player.id === contestant.id);
    state.contestants = state.contestants.map(obj => obj.id == contestant.id ? contestant : obj);
    state.picked = contestant;
  }
};

const actions = {
  async get({ commit }, data) {
    commit(SET_CONTESTANTS, data);
  },

  set({ commit }, data) {
    commit(SET_CONTESTANTS, data);
  },

  pick({ commit }, data) {
      commit(SET_CONTESTANT, data);
  }
};

const getters = {
  contestants: (state) => state.contestants,
  picked: (state) => state.picked,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
