import Vue from "vue";
import Vuex from "vuex";
import timelogs from "./modules/timelogs";
import giveaway from "./modules/giveaway";
import IO from './plugins/socket.io';

Vue.use(Vuex);

const server_info = {
  HostName: "CS:GOstivar [Offline]",
  Map: "none",
  Players: 0,
  MaxPlayers: 0
};

export default new Vuex.Store({
  state: {
    server: [],
    active: null,
  },
  mutations: {
    SET_INFO(state, info) {
      let servers = [];
      info.map((server) => {
        server.info = server.info || server_info;
        server.info &&
          servers.push({
            title: server.name.split("|")
              .shift()
              .trim(),
            max_players: server.maxplayers,
            map: server.map.includes("/")
              ? server.map.split("/")
                  .slice(-1)
                  .pop()
              : server.map,
            players: server.players || [],
          });
      });

      state.server = servers;
      state.active = state.active
        ? servers.find((server) => server.title == state.active.title)
        : servers[0];
      //state.active = state.active || servers[1];
    },

    SET_ACTIVE(state, server) {
      state.active = server;
    },
  },
  actions: {
    setInfo({ commit }, info) {
      commit("SET_INFO", info);
    },

    setActive({ commit }, server) {
      commit("SET_ACTIVE", server);
    },

    changeActive({ commit, dispatch }, server) {
      dispatch("getInfo");
      commit("SET_ACTIVE", server);
    },

    async getInfo({ commit }) {
      let response = await fetch("https://dev.level3.mk:555/status");
      let data = await response.json();
      commit("SET_INFO", data);

      return data;
    },
  },
  modules: {
    timelogs,
    giveaway
  },

  getters: {
    servers: (state) => state.server,
    active: (state) => state.active,
    players: (state) => state.server.players,
  },

  plugins: [IO()]
});
