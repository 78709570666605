import io from 'socket.io-client';
// import { TRIGGER_ALARM } from '../mutation-types';

export default function createSocketIOPlugin() {
    return store => {
        const socket = io('https://dev.level3.mk:557', { secure: true });
        socket
            .on('connect', () => console.log('Socket connected...'))
            .on('disconnect', () => store.dispatch("giveaway/set", []))
            .on('giveawayData', data => store.dispatch("giveaway/set", data))
            .on('giveawayPick', data => store.dispatch("giveaway/pick", data))
            .on('info', data => store.dispatch('setInfo', data));

        // store.subscribe(mutation => {
        //     console.log(mutation);
        // })
    };
}